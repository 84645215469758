import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Privacy from "./Privacy";
import ThemeSwitcher from "./ThemeSwitcher";

//top level switch, sub areas have their own routes
export default (props) => (

  <Switch>
        <Route path="/public/home" exact component={Home} />
        <Route path="/public/about" exact component={About} />
        <Route path="/public/privacy" exact component={Privacy} />
        <Route path="/public/themeswitcher" exact component={ThemeSwitcher} />
  </Switch>
)
