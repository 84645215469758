import React, { Component }  from "react";
import NavBarTop from "./components/public/NavBarTop";
import Routes from "./routing/Routes";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { loadAnyCachedUser } from "./components/member/UserAuth";

Amplify.configure(awsconfig);

//this was an arrow function, changed to class to access componentDidMount to call intial getAuthedUser
export default class App extends Component {

  componentDidMount() {
    loadAnyCachedUser();
  }

  render() {

    return (
      <React.StrictMode>
        <div className="App container">
            <NavBarTop />
            <Routes cookies={this.props.cookies} />
        </div>
      </React.StrictMode>
    );

  }

}
