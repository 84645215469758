// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "victrix-20190312111917-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://victrix-20190312111917-hostingbucket-prod.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:e25ae137-6bc5-488b-8171-2b43121143fa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0VxQnDlRS",
    "aws_user_pools_web_client_id": "5s0tvbb2g5k3qvca9a6073mfuv",
    "oauth": {
        "domain": "victrix48c5ff01-48c5ff01-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://victrix.gg/member/signin/",
        "redirectSignOut": "https://victrix.gg/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
