import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from './App';
import { Logger } from 'aws-amplify';
import StoreRouter from "./redux/store";
import './index.css';
import * as serviceWorker from './serviceWorker';

//TODO get from env var
Logger.LOG_LEVEL = 'DEBUG';

//the store contains a router history
const storeRouter = new StoreRouter();

ReactDOM.render(
    <Provider store={storeRouter.getStore()}>
      <ConnectedRouter history={storeRouter.getHistory()} >
        <App />
      </ConnectedRouter>
    </Provider>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
