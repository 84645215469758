import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'
import StoreRouter from "../redux/store";
import { actions as UserActions } from "../components/member/userDuck";
import { signInUser, setIntendedRouteCookie } from "../components/member/UserAuth";

//the redux store also controls routing so they are merged in a global singleton class
const store = new StoreRouter().getStore();

export default function PrivateGroupRoute({
  component: Component,
  ...rest
}) {

  //TODO userActions
  const userName = store.getState().userReducer.userName;

  if (!userName) {
    //user needs to sign in and try again
    //since we are using AWS amplify authenticator, put the desired path in the store
    //so we can pass it to the authenticator
    const requestedPath = rest.location.pathname;
    setIntendedRouteCookie(requestedPath);
    return(  signInUser() );

  } else {

    //TODO USERACTIONS
    const usersGroups = store.getState().userReducer.userGroups;
    const requiredUserGroups = rest.requiredUserGroups
    const userHadRequiredGroups = userHasRequiredGroups(usersGroups, requiredUserGroups);

    //we'll be using render props to render the actual component
    return (<  Route { ...rest }
      render={
        (props) => (
          userHadRequiredGroups ?
            < Component { ...props } /> 
            :
            < Redirect to={ `/member/missinggroups/${requiredUserGroups}`  }  />
        )
      }
    />
    );
  }
}

function userHasRequiredGroups(usersGroups, requiredUserGroups) {

  if (!requiredUserGroups) {
    return true;

  } else {

    //we required user groups but the user didnt have any
    if (!usersGroups) {
      return false;
    }

    const hasAllRequiredGroups = usersGroups.filter(function (elem) {
      return requiredUserGroups.indexOf(elem) > -1;
    }).length === requiredUserGroups.length;

    return hasAllRequiredGroups;

  }

}



