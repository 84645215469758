import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="About">
        <h2>About Victrix.gg</h2>
        <h4>This page is public. Its about what the site does. 4/30/2019 v4</h4>
      </div>
    );
  }
}

