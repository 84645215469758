import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateGroupRoute from "./PrivateGroupRoute";
import Home from "../components/public/Home";
import PublicRoutes from "../components/public/PublicRoutes";
import NotFound from "../components/public/NotFound";

//CodeSplitting supports lazy load of the sub apps in case they arent used
//but also breaking code into chunks that can stay cached if their sub app code has not changed (the chunk filename will be the same as before)
//https://reactjs.org/docs/code-splitting.html
const MemberRoutes = lazy(() => import("../components/member/MemberRoutes"));
const SiteAdminRoutes = lazy(() => import("../components/siteadmin/SiteAdminRoutes"));
//TODO rest of apps here

//TODO error boundaries
//<PrivateGroupRoute exact path="/members" component={Members} />
//<PrivateGroupRoute exact path="/admins" component={Admins} requiredUserGroups={["VictrixAdmins"]} />

//top level switch, sub areas have their own routes
export default (props) =>
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>

      <Route exact path="/" component={Home} />
      <Route path="/public/" component={PublicRoutes} />
      <PrivateGroupRoute path="/member/" component={MemberRoutes} />
      <PrivateGroupRoute path="/siteadmin/" component={SiteAdminRoutes} requiredUserGroups={["VictrixAdmins"]} />

      { /* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
      
    </Switch>
  </Suspense>;
