import React from "react";
import { Link } from 'react-router-dom';

//react css is normal name in camelCase, so text-align is textAlign
const styles = {
  textAlign: 'center'
};

//TODO put privacy in footer
const Home = () => (

      <div style={styles}>
        
        <h1>Victrix</h1>
        <p>Team Management</p>
        <Link to='/public/about' >About</Link>
        <br />
        <Link to='/public/privacy' >Privacy</Link>
        <br />
        <Link to='/member/memberhome' >Members Only</Link>
        <br />
        <Link to="/siteadmin/siteadminhome" >Site Admins Only</Link>
        <br />
        <Link to='/public/themeswitcher' >ThemeSwitcher</Link>

      </div>

)

export default Home;