import { createStore, applyMiddleware } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
//each modules duck reducers added here
import { userReducer } from "../components/member/userDuck"

//TODO implement Redux-saga

/* 
The name of this class is "store" to help people looking for the redux store. However, I would otherwise call it "RoutingStore" or "StoreWithHistory"
Using a singleton with constructor pattern because we need to be able to lazy init with optional passed in preloadedState
*/

//enable tracing in Redux dev tools
/*
//this version works without installing redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
*/

//TODO how is this diff from above?
const composeEnhancers = composeWithDevTools({
    name: `Redux`,
    realtime: true,
    trace: true,
    traceLimit: 25
});

//singleton
let instance = null;

//TODO: rename to StoreWithHistory
export default class StoreRouter {

    constructor(preloadedState) {

        if (!instance) {
            instance = this;
        } else {
            console.log("StoreRouter: constructor was called but instance already existed.")
            return instance;
        }

        //leaving these consts inside the constructor to support private properties
        const history = createBrowserHistory();

        const store = createStore(
            createRootReducer(history), //root reducer with router state
            preloadedState,
            composeEnhancers(
                applyMiddleware(
                    routerMiddleware(history) //for dispatching history actions
                    //other middlwares
                ),
            ),
        )

        //accessors
        this.getHistory = function () { return history; }
        this.getStore = function () { return store; }

        instance = Object.freeze(instance);

    }

}

//We accept a history object to support storing navigation events in redux
function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        userReducer
    });
}


