//TODO move from set_user_name, groups, to user_signed_in, user_signed_out
export const types = {
    USER_SIGNED_IN : "USER_SIGNED_IN",
    USER_SIGNED_OUT: "USER_SIGNED_OUT",
    SET_USER_NAME : "SET_USER_NAME",
    SET_USER_GROUPS : "SET_USER_GROUPS",
    SET_USER_LOGIN_REDIRECT : "SET_USER_LOGIN_REDIRECT",
}

export const initialState = {
    userObject : null,
    userName: "",
    userGroups: [],
    intendedRoute: ""
}
//TODO what is mapDispatchToProps in https://hackernoon.com/my-journey-toward-a-maintainable-project-structure-for-react-redux-b05dfd999b5
//TODO implement selectors and sagas
export function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.USER_SIGNED_IN:
            return Object.assign({}, state, {
                user: action.userObject
            })
        case types.USER_SIGNED_OUT:
            return Object.assign({}, state, {
                user: null
            })
        case types.SET_USER_NAME:
            return Object.assign({}, state, {
                userName: action.userName
            })
        case types.SET_USER_GROUPS:
            return Object.assign({}, state, {
                userGroups: action.userGroups
            })
        case types.SET_USER_LOGIN_REDIRECT:
            return Object.assign({}, state, {
                intendedRoute : action.intendedRoute
            })
        default:
            return state;
    }
} 

export const actions ={
    userSignedIn: (userObject) => ({ type: types.USER_SIGNED_IN, userObject }),
    userSignedOut: (userObject) => ({ type: types.USER_SIGNED_OUT, userObject }),
    setUserLoginRedirect: (intendedRoute) => ({ type: types.SET_USER_LOGIN_REDIRECT, intendedRoute }),
    setUserName: (userName) => ({ type: types.SET_USER_NAME, userName }),
    setUserGroups: (userGroups) => ({ type: types.SET_USER_GROUPS, userGroups })
}