import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

class ThemeSwitcher extends Component {

    state = { theme: null }

    chooseTheme = (theme, evt) => {
        evt.preventDefault();
        if (theme.toLowerCase() === 'reset') { theme = null }
        this.setState({ theme });
    }

    render() {

        const { theme } = this.state;
        const themeClass = theme ? theme.toLowerCase() : 'default';

        //react css is normal name in camelCase, so text-align is textAlign
        //TODO explore styled-components and moving this out of render func
        const parentContainerStyles = {
            textAlign: 'center'
        };

        return (
            <>
                <h2>Themeswitcher</h2>
                <h4>A proof of concept about changing CSS style on the fly so clients can brand their Organizations site.</h4>
                <div style={parentContainerStyles}>
                    <div>

                        <span className={`h1 center-block text-center text-${theme ? themeClass : 'muted'}`} style={{ marginBottom: 25 }}>{theme || 'Default'}</span>

                        <div className="center-block text-center">
                            <DropdownButton size="lg" title={`${theme || 'Default'} Theme`}>
                                <Dropdown.Item eventKey="Primary" onSelect={this.chooseTheme}>Primary Theme</Dropdown.Item>
                                <Dropdown.Item eventKey="Danger" onSelect={this.chooseTheme}>Danger Theme</Dropdown.Item>
                                <Dropdown.Item eventKey="Success" onSelect={this.chooseTheme}>Success Theme</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="Reset" onSelect={this.chooseTheme}>Default Theme</Dropdown.Item>
                            </DropdownButton>
                        </div>

                    </div>
                </div>
            </>
        );

    }

}

export default ThemeSwitcher;