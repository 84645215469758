import { Auth, Hub, Logger } from 'aws-amplify';
import StoreRouter from "../../redux/store";
import { actions as UserActions } from "./userDuck";
import Cookies from 'universal-cookie';

const logger = new Logger("AppUser_HubWatcher", "DEBUG");
logger.debug("AppUser logger loaded");

logger.debug("AppUser attempting to grab the storeRouter singleton.");
const history = new StoreRouter().getHistory();//is a singleton
const store = new StoreRouter().getStore();//is a singleton

function onAuthEvent(payload){
    switch (payload.event) {
        //other events: oAuthSignOut (followed by a signIn event), codeFlow, cognitoHostedUI
        case 'signIn': 
            handleUserSignIn();
            break;
        case 'signOut':
            handleUserSignOut();
            break;
        case 'signIn_failure':
            break;
        default:
            logger.debug("No case defined for event: " + payload.event + " msg: " + payload.message);
    }
}

//auth is a channel published by the amplify authenticator.
//note: other channels are analytics and storage (not well documented)
Hub.listen('auth', (data) => {
    const { payload } = data;
    onAuthEvent(payload);               
})

//I've added this function for clarity of flow.
//We have to call handleUserSignIn when the app loads because if there is a user in the browser session
//then aws auth will load it but not dispatch a signIn event, so the navbar will not 
//render correctly 
//TODO a bug that should be reported to AWS
export function loadAnyCachedUser(){
    logger.debug("loadAnyCachedUser() called");
    handleUserSignIn();
}

export function signInUser(){
    Auth.federatedSignIn();
}

//grab the username from the now signed in  user
//redirect to original destination, if any, then clear it from state
function handleUserSignIn() {
    
    Auth.currentAuthenticatedUser({
        // bypassCache is Optional, By default is false. 
        // If set to true, this call will send a request to Cognito to get the latest user data, 
        // and ignore any authed user left in the browser session
        bypassCache: false  
    
    }).then(user => {

        setUserStoreData(user);
        useIntendedRouteCookie();
           
    }).catch(err => logger.error("handleSignIn error: " + err)); 
    
}

function setUserStoreData(user){
    //user.attributes.picture: "https://lh3.googleusercontent.com/-GyX6olsnA7A/AAAAAAAAAAI/AAAAAAAAP3E/xHBd6HF63SI/s96-c/photo.jpg
    //we put the user in Redux, we are trying to keep the user auth decoupled from specific identity mgt
    //TODO create an abstracted User class and simply populate it
    store.dispatch( UserActions.userSignedIn(user) );

    const userName = user.username;
    const name = user.attributes.name;
    logger.info("User logged in: name: " + name + ", email: " + user.attributes.email + ", username: " + userName );

    store.dispatch( UserActions.setUserName(name) );

    //groups are in user.signInUserSession.idToken.payload.cognito:groups[] (but groups wont exist if not in any groups)
    let thisUserGroups = user.signInUserSession.idToken.payload["cognito:groups"];
    if(!thisUserGroups) thisUserGroups = [];
    logger.debug("user groups: " + thisUserGroups );
    store.dispatch( UserActions.setUserGroups(thisUserGroups) );
}

//store intendedroute in redux
export function setIntendedRoute(requestedPath){
    store.dispatch(UserActions.setUserLoginRedirect(requestedPath));
}

function useIntendedRoute(){
    //if there is an intendedRoute...
    const intendedRoute = store.getState().userReducer.intendedRoute;
    if(intendedRoute){
        //clear it
        store.dispatch( UserActions.setUserLoginRedirect("") );
        //send them there
        history.push(intendedRoute);
    }else{
        //otherwise send them to root
        history.push('/');
    }
}

//had to use this because Cognito hosted ui for social log in redirects away from app and destroys Redux record of intended route
//so using cookies instead
export function setIntendedRouteCookie(intendedRoute){
    const cookies = new Cookies();
    cookies.set("intendedRoute", intendedRoute, { path: "/" } );
}

function useIntendedRouteCookie(){
    const cookies = new Cookies();
    const intendedRoute = cookies.get("intendedRoute");
    if(intendedRoute){
        //clear it
        cookies.set("intendedRoute", "", { path: "/" } );
        //send them there
        history.push(intendedRoute);
    }else{
        //otherwise send them to root
        history.push('/');
    }
}

export const signOutUser = () => {
    logger.debug("signOutUser called");

    //this will fire the hub listener which will then call handleSignOut
    Auth.signOut()
        .then(data => {
            //if data is undefined, there was no logged in user
            if (!data) {
                logger.info("signOutUser was called, but no user was logged in");
            } else {
                logger.info("User was signed out");
            }
        })
        .catch(err => logger.error(err));
}

function handleUserSignOut() {
    store.dispatch( UserActions.userSignedOut(null));
    store.dispatch( UserActions.setUserName("") );
    store.dispatch( UserActions.setUserGroups([]) );
    history.push("/");
}
