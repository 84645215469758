import React, { Component, Fragment } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import logo from "../../victrix_logo.svg.png";
import { signInUser, signOutUser } from "../member/UserAuth";
//https://www.npmjs.com/package/react-icons

//TODO change this to use redux for signout
const mybox = {
  display: "flex",
  border: 2,
  alignItems: "center"
}

const customCursor = {
  cursor: "pointer"
}

class NavBarTop extends Component {

  render() {

    return (
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" >
        <Navbar.Brand >
          <LinkContainer to="/" style={customCursor} >
            <div style={mybox}>
              <img
                alt="logo"
                src={logo}
                height="50px"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              Victrix
            </div>
          </LinkContainer>


        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="mr-auto">
            {/* this nav block here as place holder and to make second nav area right justify */}
          </Nav>
          <Nav className="justify-content-end">

            {
              !this.props.userName ?
                <Fragment>
                  <Nav.Item onClick={() => signInUser()} style={customCursor} >Sign In</Nav.Item>
                </Fragment>
                :
                <Fragment>
                  <Nav.Item style={customCursor} >Hi {this.props.userName}</Nav.Item>
                  &nbsp;&nbsp;&nbsp;
                  <Nav.Item onClick={() => signOutUser()} style={customCursor} >Sign Out</Nav.Item>  
                </Fragment>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    userName: state.userReducer.userName
  };
}

export default connect(mapStateToProps)(NavBarTop);
